import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Picture8 from '../images/picture8.jpg';
import Picture9 from '../images/picture9.jpg';



function setVw() {
    let vw = document.documentElement.clientWidth / 100;
    document.documentElement.style.setProperty('--vw', `${vw}px`);
}
setVw();
window.addEventListener('resize', setVw);

const style = {
    col_: {
        minHeight: '20em',
        lineHeight: '30px'
    },
    col_text: {
        padding: '100px',
    },
    triangle: {
        width: 0,
        height: 0,
        left: 0,
        maxWidth: "calc(var(--vw, 1vw) * 100)",
        borderStyle: "solid",
        borderWidth: "4vh 0 30px calc(var(--vw, 1vw) * 100 - 23px)",
        borderColor: "rgb(143 156 149) transparent transparent"
    }
}



function Gezondheid() {



    return (
        <>
            {/* <Image fluid src={Picture1} style={{ maxHeight: '50em', borderRadius: '15px' }} alt="bedrijfs Logo" /> */}
            <Container fluid className="mt-header-menu">
                <Row>
                    <Col xs={12} s={12} md={12} lg={6} className='picture_background' style={{ backgroundImage: 'linear-gradient(to bottom, transparent 0%, transparent 80%, #46544c 99%), url(' + Picture9 + ')' }}> </Col>
                    <Col xs={12} s={12} md={12} lg={6} className='col_ col_text' style={{ backgroundColor: '#8f9c95', fontSize: "1.5vh", color: '#ffffff' }}><h2>Gezondheid</h2>
                        <br />
                        Je bent wat je eet….<br />
                        Steeds meer zijn we ervan bewust dat voeding een hele belangrijke rol speelt in hoe je je voelt. <br />
                        Vaak weten we niet eens precies meer wat nou wel en wat nou niet goed is. Ook omdat het lijkt dat hier elke keer een nieuwe trend in is. <br />
                        Toch is het voor een goede gezondheid belangrijk om te weten wat we nou precies eten. Maar niet alleen wat je eet, ook hoe vaak je op een dag eet. <br />
                        Voor zowel afvallen als voor opbouwen van spiermassa kunnen kleine veranderingen in eetpatronen al een heleboel te weeg brengen. <br />
                        Ik ga geen hele eetplannen voor je uitschrijven, puur omdat dit meestal niet werkt. Dit houden mensen vaak niet vol. Wat we samen gaan doen is stapje voor stapje jouw patroon aanpassen. Op een manier wat wel voor een langere tijd vol te houden is. <br />
                        Niks crash diëten, het aanpassen van je levensstijl is wat we gaan doen.  <br />
                        <br />

                    </Col>
                    <Col xs={12} s={12} md={12} lg={6} style={style.triangle}></Col>
                </Row>
                <Row>

                    <Col xs={{ span: 12, order: 2 }} s={12} lg={7} className='col_ col_text order-xs-2 order-sm-2 order-lg-1' style={{ backgroundColor: '#FFFFFF', fontSize: "1.5vh", color: '#8f9c95' }}><h2>Voeding.</h2>
                        <br />
                        Wil jij dat we samen gaan kijken hoe jij je voeding kan aanpassen of wellicht werken aan een optimale gezondheid dmv voedingssupplementen? <br />
                        <br />
                        Geef even een bellentje en wij maken samen een vrijblijvende afspraak.<br />
                    </Col>
                    <Col xs={{ span: 12, order: 1 }} s={12} lg={5} className='picture_background order-xs-1 order-sm-1 order-lg-2' style={{ backgroundImage: 'linear-gradient(to bottom, #FFFFFF 0%, transparent 20%, transparent 80%, #FFFFFF 99%),url(' + Picture8 + ')' }}> </Col>
                </Row>
            </Container>
        </>
    )
}

export default Gezondheid;