import React, { useState, useEffect, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Picture9 from '../images/picture9.jpg';
import axios from 'axios';

function Contact() {

 const [isFormDisabled, setIsFormDisabled] = useState(() => {
        const opgeslagenData = JSON.parse(localStorage.getItem('formState')) || {};
        const { isFormDisabled, enableTime } = opgeslagenData;

        // Schakel het formulier automatisch in als enableTime in het verleden ligt
        if (isFormDisabled && enableTime && enableTime < new Date().getTime()) {
            localStorage.removeItem('formState');
            return false;
        }

        return isFormDisabled || false;
    });


    const [formData, setFormData] = useState({
        email: '',
        message: '',
        name: '',
        hiddenField: ''
    });

   

    const [responseMessage, setResponseMessage] = useState('');
    const [countdownText, setCountdownText] = useState('');

    const [enableTime] = useState(() => {
        const opgeslagenData = JSON.parse(localStorage.getItem('formState')) || {};
        return opgeslagenData.enableTime || 0;
    });

    const isGemonteerd = useRef(true);

    useEffect(() => {
        return () => {
            // Opruimen en de component als niet gemonteerd markeren
            isGemonteerd.current = false;
        };
    }, []);

    useEffect(() => {
        if (isFormDisabled && isGemonteerd.current) {
            // Schakel het formulier in na 5 minuten
            const enableFormTimer = setTimeout(() => {
                setIsFormDisabled(false);
                localStorage.removeItem('formState');
            }, 5 * 60 * 1000);

            // Sla de enable time op in de lokale opslag
            //const enableTime = new Date().getTime() + 5 * 60 * 1000;
            //localStorage.setItem('formState', JSON.stringify({ isFormDisabled, enableTime }));

            const countdownTimer = setInterval(() => {
                const remainingTime = enableTime - new Date().getTime();

                if (remainingTime > 0) {
                    const minutes = Math.floor(remainingTime / (60 * 1000));
                    const seconds = Math.floor((remainingTime % (60 * 1000)) / 1000);
                    setCountdownText(`${minutes}:${seconds}`);
                } else {
                    setCountdownText(''); // Reset countdown text when time is up
                }
            }, 1000);


            // Opruimen van de timer bij het ontmantelen van de component
            return () => {
                clearTimeout(enableFormTimer);
                clearInterval(countdownTimer);
            };
        }
    }, [isFormDisabled, enableTime]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value});
    };

    const validateEmail = (email) => {
        // Basic email validation using a regular expression
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if the hidden field is filled (indicating a bot)
        if (formData.hiddenField !== '') {
            setResponseMessage('Bot gededecteerd, probeer het opnieuw');
            return;
        }

        if (formData.name === '') {
            setResponseMessage('Naam is leeg deze is verplicht');
            return;
        }

        if (formData.message === '') {
            setResponseMessage('Bericht is leeg deze is verplicht');
            return;
        }

        if (isFormDisabled) {
            setResponseMessage('Het formulier is uitgeschakeld. Wacht even voordat je opnieuw indient.');
            return;
        }

        if (!validateEmail(formData.email)) {
            setResponseMessage('Ongeldig e-mailadres. Controleer het e-mailadres en probeer opnieuw.');
            return;
        }

        
        try {
            setIsFormDisabled(true);
            const enableTime = new Date().getTime() + 5 * 60 * 1000;
            localStorage.setItem('formState', JSON.stringify({ isFormDisabled: true, enableTime }));
            setTimeout(async () => {
                try {
                    const response = await axios.post('https://www.theboxz.nl/API/mail.php', formData);
    
                    if (response.status === 200) {
                        // console.log('E-mail succesvol verzonden');
                        setResponseMessage('E-mail succesvol verzonden');
                        setFormData({email:"",name:"", message:""})
                    } else {
                        // console.error('Fout bij het verzenden van de e-mail');
                        setResponseMessage('Fout bij het verzenden van de e-mail');
                    }
                } catch (error) {
                    // console.error('Fout bij het verzenden van de e-mail:', error);
                    setResponseMessage('Fout bij het verzenden van de e-mail');
                } 
            }, 1000); // 1000 milliseconds = 1 second
        } catch (error) {
            // console.error('Error:', error);
            setResponseMessage('Error bij het verwerken van het formulier');
        }
    }

    const style = {
        col_: {
            minHeight: '20em',
            lineHeight: '30px'
        },
        col_text: {
            padding: '100px',
        },
        triangle: {
            width: 0,
            height: 0,
            left: 0,
            maxWidth: "calc(var(--vw, 1vw) * 100)",
            borderStyle: "solid",
            borderWidth: "4vh 0 30px calc(var(--vw, 1vw) * 100 - 23px)",
            borderColor: "rgb(143 156 149) transparent transparent"
        }
    }
    return (
        <>
            {/* <Image fluid src={Picture1} style={{ maxHeight: '50em', borderRadius: '15px' }} alt="bedrijfs Logo" /> */}
            <Container fluid className="mt-header-menu">
                <Row>
                    <Col xs={12} s={12} md={12} lg={6} className='picture_background' style={{ backgroundImage: 'linear-gradient(to bottom, transparent 0%, transparent 80%, #46544c 99%), url(' + Picture9 + ')' }}> </Col>
                    <Col xs={12} s={12} md={12} lg={6} className='col_ col_text' style={{ backgroundColor: '#8f9c95', fontSize: "1.5vh", color: '#ffffff' }}><h2>Contact</h2>
                        <br />

                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="name">Naam</label>
                                <input type="text" className="form-control" id="name" name="name" value={formData.name} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Email adres</label>
                                <input type="email" className="form-control" id="email" aria-describedby="emailHelp" name="email" value={formData.email} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="message">Bericht</label>
                                <textarea className="form-control" rows="5" id="message" name="message" value={formData.message} onChange={handleChange} />
                            </div>
                            <input
                                type="text"
                                name="hiddenField"
                                style={{ display: 'none' }}
                                value={formData.hiddenField}
                                onChange={handleChange}
                            />
                            <br />
                            <button type="submit" className="btn btn-success">Verzenden</button>{countdownText && <p>Nog te gaan: {countdownText} voordat u weer een mail kan versturen</p>}
                        </form>
                        {/* Display the response message */}
                        {responseMessage && <p>{responseMessage}</p>}

                    </Col>
                    <Col xs={12} s={12} md={12} lg={6} style={style.triangle}></Col>
                </Row>
            </Container>
        </>
    )
}
export default Contact;