import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Picture11 from '../images/picture11.jpg';



function setVw() {
    let vw = document.documentElement.clientWidth / 100;
    document.documentElement.style.setProperty('--vw', `${vw}px`);
}
setVw();
window.addEventListener('resize', setVw);

const style = {
    col_: {
        minHeight: '20em',
        lineHeight: '30px'
    },
    col_text: {
        padding: '100px',
    },
    triangle: {
        width: 0,
        height: 0,
        left: 0,
        maxWidth: "calc(var(--vw, 1vw) * 100)",
        borderStyle: "solid",
        borderWidth: "4vh 0 30px calc(var(--vw, 1vw) * 100 - 23px)",
        borderColor: "rgb(143 156 149) transparent transparent"
    }
}




function Rooster() {

    const [SmallGroup] = useState([
        { dag: 'Maandag', van: '19:00', tot: '19:50' },
        { dag: 'Dinsdag', van: '19:30', tot: '20:20' },
        { dag: 'Woensdag', van: '09:00', tot: '09:50' },
        { dag: '', van: '18:30', tot: '19:20' },
        { dag: 'Donderdag', van: '19:00', tot: '19:50' },
        { dag: 'Vrijdag', van: '09:00', tot: '09:50' },
        { dag: 'Zaterdag', van: '08:30', tot: '09:20' },
        { dag: '', van: '09:30', tot: '10:20' },
        // { dag: 'Zondag', van: 'Gesloten', tot: 'Gesloten' },
    ]);

    return (
        <>
            {/* <Image fluid src={Picture1} style={{ maxHeight: '50em', borderRadius: '15px' }} alt="bedrijfs Logo" /> */}
            <Container fluid className="mt-header-menu">
                <Row>
                    <Col xs={12} s={12} md={12} lg={6} className='picture_background' style={{ backgroundImage: 'linear-gradient(to bottom, transparent 0%, transparent 80%, #46544c 99%), url(' + Picture11 + ')' }}> </Col>
                    <Col xs={12} s={12} md={12} lg={6} className='col_ col_text' style={{ backgroundColor: '#8f9c95', fontSize: "1.5vh", color: '#ffffff' }}><h2>Overzicht lesaanbod</h2>
                        <br />
                        <h4>Small group Trainingen</h4>
                        <table style={{borderRadius: "5px", border:"solid 2px", width: "-webkit-fill-available"}}>
                            <thead>
                                <tr>
                                    <th>Dag</th>
                                    <th>Van</th>
                                    <th>Tot</th>
                                </tr>
                            </thead>
                            <tbody>
                                {SmallGroup.map((item, index) => (
                                    <tr key={index} style={{ backgroundColor: index % 2 === 0 ? 'rgb(143, 156, 149)' : '#ffffff' , color: index % 2 === 0 ? '#ffffff' : '#000000' }}>
                                        <td>{item.dag}</td>
                                        <td>{item.van}</td>
                                        <td>{item.tot}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Col>
                    <Col xs={12} s={12} md={12} lg={6} style={style.triangle}></Col>
                </Row>
                {/* <Row>

                    <Col xs={{ span: 12, order: 2 }} s={12} lg={7} className='col_ col_text order-xs-2 order-sm-2 order-lg-1' style={{ backgroundColor: '#FFFFFF', fontSize: "1.5vh", color: '#8f9c95' }}><h2>Ik blijf mijzelf graag uitdagen.</h2>
                        <br />
                        Ik vind het gaaf om steeds weer iets nieuws te leren door diverse opleidingen/cursussen te blijven volgen zodat ik elke sporter als individu kan helpen. <br />
                        <br />
                        Van krachttraining met KB’s, barbell tot cardio. <br />
                        Voor de ervaren sporten, de beginner tot aan de pre- en post natale sporter.<br />
                        <br />
                        <a href='/Aanbod' style={{ color: "rgb(143, 156, 149)" }}>Voor meer info kijk onder aanbod.</a><br />
                    </Col>
                    <Col xs={{ span: 12, order: 1 }} s={12} lg={5} className='picture_background order-xs-1 order-sm-1 order-lg-2' style={{ backgroundImage: 'linear-gradient(to bottom, #FFFFFF 0%, transparent 20%, transparent 80%, #FFFFFF 99%),url(' + Picture5 + ')' }}> </Col>
                </Row> */}
            </Container>
        </>
    )
}

export default Rooster;