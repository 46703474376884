import React from 'react';

import { BrowserRouter as Router,Routes, Route} from 'react-router-dom';
import Menu from './components/menu';

import Home from './screens/Home';
import Contact from './screens/Contact';
import Media from './screens/Media';


import './App.css';
import Footer from './components/footer';
import Aanbod from './screens/Aanbod';
import Gezondheid from './screens/Gezondheid';
import Webshop from './screens/Webshop';
import Rooster from './screens/Rooster';

function App() {
  return (
    <Router>
      {/* <div className="App">
        <ul className="App-header">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About Us</Link>
          </li>
          <li>
            <Link to="/contact">Contact Us</Link>
          </li>
        </ul> */}
        <Menu/>
        <Routes>
          <Route exact path='/' element={< Home />}></Route>
          <Route exact path='/About' element={< Home />}></Route>
          <Route exact path='/Aanbod' element={< Aanbod />}></Route>
          <Route exact path='/Rooster' element={< Rooster />}></Route>
          <Route exact path='/Gezondheid' element={< Gezondheid />}></Route>
          <Route exact path='/Webshop' element={< Webshop />}></Route>
          <Route exact path='/Media' element={< Media />}></Route>
          <Route exact path='/contact' element={< Contact />}></Route>
        </Routes>
        <Footer/>
    </Router>
    
  );
}

export default App;
