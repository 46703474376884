import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


// const style = {
//     col_: {
//         minHeight: '20em',
//         lineHeight: '30px'
//     },
//     col_text: {
//         padding: '100px',
//     },
//     triangle: {
//         width: 0,
//         height: 0,
//         left: 0,
//         maxWidth: "calc(var(--vw, 1vw) * 100)",
//         borderStyle: "solid",
//         borderWidth: "4vh 0 30px calc(var(--vw, 1vw) * 100 - 23px)",
//         borderColor: "rgb(143 156 149) transparent transparent"
//         },
//         picture_background: {
//             position: "relative",
//             backgroundRepeat: "no-repeat !important",
//             backgroundSize: "cover !important",
//             opacity: "0.6",
//             backgroundPositionX: "center",
//             paddingLeft: 0,
//             paddingRight: 0,
//             backgroundColor: "rgb(67 90 78)"
//           }
// }

function Webshop() {



    return (
        <>
            {/* <Image fluid src={Picture1} style={{ maxHeight: '50em', borderRadius: '15px' }} alt="bedrijfs Logo" /> */}
            <Container fluid className="mt-header-menu">
                <Row>
                <Col xs={{span: 12, order: 2 }} s={12}  lg={12} style={{padding: 0}} className='col_ order-xs-2 order-sm-2 order-lg-1 text-center justify-content-md-center' >
                    <iframe  
                    title='lindavandervoortbewegingscoach Webshop'   
                    src="https://lindavandervoortbewegingscoach.virtuagym.com/webshop/?club=MWk4U0NNTEZLVWJTbGkxUlQwVUtMUT09&embedded=1"  
                    scrolling="yes"  
                    iframeborder="0"  
                    style={{border: "none", width: "100vw", height: "100vh"}} 
                    />



                </Col>
                </Row>
            </Container>
        </>
    )
}

export default Webshop;