import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image'
import Offcanvas from 'react-bootstrap/Offcanvas';

import menuLogo from '../images/logo_linda.png';

//import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';


import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import { Col } from 'react-bootstrap';


const Menu = () => {
    const style = {
        links: {
            fontSize: '20px',
            lineHeight: '1em',
            fontWeight: '600',
            textTransform: 'none',
            padding: '20px 0px',
            letterSpacing: '0.5px',
            display: 'block',
            position: 'relative',
            color: '#8f9c95',
        },
        sub_links: {
            fontSize: '16px',
            lineHeight: '1em',
            fontWeight: '600',
            textTransform: 'none',
            padding: '10px 0px 0px 40px',
            letterSpacing: '0.5px',
            display: 'block',
            position: 'relative',
            color: '#8f9c95',
        },
        header: {
            justifyContent: 'normal',
            paddingLeft: '70%',
            color: '#8f9c95'
        },
        body: {
            display: 'table-cell',
            padding: '40px 50px',
            width: '100%',
            backgroundColor: ''
        },
        logo: {
            marginBottom: '40px'
        }
    }
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            {[false].map((expand) => (
                <Navbar key={expand} style={{ backgroundColor: '#ffffff' }} expand="false" fixed="top">
                    <Container fluid>
                        <Image style={{ width: '200px' }} fluid className='mx-auto d-block' src={menuLogo} alt="bedrijfs Logo" />
                        <Col onClick={handleShow} style={{ float: "left", position: "absolute", borderColor: "#8f9c95" }} aria-controls={`offcanvasNavbar-expand-${expand}`}><i style={{ fontSize: '5em', color: "#8f9c95" }} className="bi bi-list"></i></Col>
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-${expand}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                            placement="start"
                            style={{ backgroundColor: '#ffffff', filter: 'drop-shadow(13px 0px 19px rgba(0, 0, 0, 0.14))' }}
                            show={show} onHide={handleClose}
                        >
                            <Offcanvas.Header closeButton style={style.header} className="offcanvas-header-custom">
                                <Offcanvas.Title onClick={handleClose}>Sluiten</Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body style={style.body}>
                                <Navbar.Brand href="#"><Image style={style.logo} fluid src={menuLogo} alt="bedrijfs Logo" /></Navbar.Brand>
                                <Nav className="justify-content-end flex-grow-1 pe-3">
                                    <Nav.Link style={style.links} href="/About">Wie is linda?</Nav.Link>
                                    <Nav.Link style={style.links} href="/Aanbod">Aanbod
                                        <Nav.Link style={style.sub_links} href="/Rooster">- Rooster</Nav.Link>
                                    </Nav.Link>

                                    <Nav.Link style={style.links} href="/Gezondheid">Gezondheid</Nav.Link>
                                    <Nav.Link style={style.links} href="/webshop">Webshop</Nav.Link>
                                    <Nav.Link style={style.links} href="/contact">Contact</Nav.Link>
                                    <Nav.Link style={style.links} href="/media">Media</Nav.Link>
                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>
            ))}
        </>
    );




}


export default Menu;