import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Picture6 from '../images/picture6.jpg';
import Picture7 from '../images/picture7.jpg';



function setVw() {
    let vw = document.documentElement.clientWidth / 100;
    document.documentElement.style.setProperty('--vw', `${vw}px`);
}
setVw();
window.addEventListener('resize', setVw);

const style = {
    col_: {
        minHeight: '20em',
        lineHeight: '30px'
    },
    col_text: {
        padding: '100px',
    },
    triangle: {
        width: 0,
        height: 0,
        left: 0,
        maxWidth: "calc(var(--vw, 1vw) * 100)",
        borderStyle: "solid",
        borderWidth: "4vh 0 30px calc(var(--vw, 1vw) * 100 - 23px)",
        borderColor: "rgb(143 156 149) transparent transparent"
    }
}



function Aanbod() {



    return (
        <>
            <Container fluid className="mt-header-menu">
                <Row>
                    <Col xs={12} s={12} md={12} lg={6} className='picture_background' style={{ backgroundImage: 'linear-gradient(to bottom, transparent 0%, transparent 80%, #46544c 99%), url(' + Picture6 + ')' }}> </Col>
                    <Col xs={12} s={12} md={12} lg={6} className='col_ col_text' style={{ backgroundColor: '#8f9c95', fontSize: "1.5vh", color: '#ffffff' }}><h2>Aanbod</h2>
                        <br />
                        Wil je het sporten weer oppakken? Ben je de motivatie een beetje kwijt? <br />
                        Of ben jij op zoek naar die stok achter de deur? <br />
                        Hou je van sporten in de buitenlucht? Vind je het leuk om eventuele grenzen op te zoeken, samen te werken aan jouw doelen? <br />
                        <br />
                        <br />
                        <h4>Weightlifting</h4>
                        <br />
                        Vind jij het leuk om meer ervaring op te doen doen met het weightlifting? <br />
                        Ook dat is mogelijk!<br />
                        Hier ligt ook een groot deel van mijn passie en uitlaatklep. En mede daarom heb ik onlangs de cursus weightlifting coach (WELIFT) afgerond.<br />
                        <a href='/contact' style={{ color: "rgb(255, 255, 255)" }}>neem contact met mij op</a><br />
                        {/* <h4>Training en coachen tijdens en na de zwangerschap</h4> 
<br/>
Ben jij zwanger, of net bevallen? Wil je graag onder begeleiding blijven sporten of het weer oppakken? <br/>
Graag help ik jouw op een professionele manier. <br/>
<br/>
Het certificaat van de PowerMama opleiding heb ik in mijn bezit.<br/> */}

                    </Col>
                    <Col xs={12} s={12} md={12} lg={6} style={style.triangle}></Col>
                </Row>
                <Row>

                    <Col xs={{ span: 12, order: 2 }} s={12} lg={7} className='col_ col_text order-xs-2 order-sm-2 order-lg-1' style={{ backgroundColor: '#FFFFFF', fontSize: "1.5vh", color: '#8f9c95' }}><h2>Het volgende kan ik voor jouw betekenen:</h2>
                        <br />
                        <strong>-	Personal training,</strong><br />
                        1 op 1 gaan wij samen aan de slag met de doelen die jij voor ogen hebt. De training zijn divers en op maat gemaakt. <br />
                        <br />
                        <strong>-	Duo training,</strong><br />
                        Samen met een maatje trainen kan soms net even gezelliger zijn of wellicht qua kosten net wat aantrekkelijker. Als nog gaan wij met jouw persoonlijke doelen aan de slag.<br />
                        <br />
                        <strong>-	Small group training,</strong><br />
                        Tijdens de smallgroup trainingen train je met max 8 andere personen.<br />
                        <br />
                        <strong>-	Zwangerschap training,</strong><br />
                        Tijdens de zwangerschap train je onder begeleiding.<br />
                        <br />
                        Bij alle trainingen zal er een combinatie zijn tussen kracht en cardio. Er wordt gelet op houding en zo nodig geschaald. Maar wanneer ik zie dat er nog een tandje bij kan dan gaan we daar uiteraard ook voor!  <br />

                    </Col>
                    <Col xs={{ span: 12, order: 1 }} s={12} lg={5} className='picture_background order-xs-1 order-sm-1 order-lg-2' style={{ backgroundImage: 'linear-gradient(to bottom, #FFFFFF 0%, transparent 20%, transparent 80%, #FFFFFF 99%),url(' + Picture7 + ')' }}> </Col>
                </Row>

            </Container>
        </>
    )
}

export default Aanbod;