import React from "react"
import "./footer.css"


const Footer = () =>
    <>
        <div className="triangle"></div>
        <div className="footer-dark">

            <footer>

                <div className="container">

                    <div className="row">
                        <div className="col-sm-6 col-md-3 item">
                            <h3>Links</h3>
                            <ul>
                                <li><a href="/About">Wie is linda?</a></li>
                                <li><a href="/Aanbod">Aanbod</a></li>
                                <li><a href="/Gezondheid">Gezondheid</a></li>
                            </ul>
                        </div>
                        <div className="col-sm-6 col-md-3 item">
                            <h3>Socials</h3>
                            <ul>
                                <li><a target="_blank" href="https://www.instagram.com/linda.van.der.voort" rel="noreferrer">Facebook</a></li>
                                <li><a target="_blank" href="https://www.facebook.com/linda.vandervoort.71" rel="noreferrer">Instagram</a></li>
                                <li><a target="_blank" href="https://wa.me/0638122899" rel="noreferrer">Whatsapp</a></li>
                            </ul>
                        </div>
                        <div className="col-md-6 item text">
                            <h3>Linda van der Voort</h3>
                            <p>uw personal trainer voor een gezonder leven</p>
                        </div>
                        <div className="col item social"><a target="_blank" href="https://www.instagram.com/linda.van.der.voort" rel="noreferrer"><i className="bi bi-instagram"></i></a><a target="_blank" href="https://www.facebook.com/linda.vandervoort.71" rel="noreferrer"><i className="bi bi-facebook"></i></a><a target="_blank" href="https://wa.me/0638122899" rel="noreferrer"><i className="bi bi-whatsapp"></i></a></div>
                    </div>
                    <p className="copyright">Linda van der Voort  © 2023</p>
                </div>
            </footer>
        </div></>
export default Footer