import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Picture10 from '../images/picture10.jpg';
import Picture5 from '../images/picture5.jpg';



function setVw() {
    let vw = document.documentElement.clientWidth / 100;
    document.documentElement.style.setProperty('--vw', `${vw}px`);
}
setVw();
window.addEventListener('resize', setVw);

const style = {
    col_: {
        minHeight: '20em',
        lineHeight: '30px'
    },
    col_text: {
        padding: '100px',
    },
    triangle: {
        width: 0,
        height: 0,
        left: 0,
        maxWidth: "calc(var(--vw, 1vw) * 100)",
        borderStyle: "solid",
        borderWidth: "4vh 0 30px calc(var(--vw, 1vw) * 100 - 23px)",
        borderColor: "rgb(143 156 149) transparent transparent"
    }
}



function Home() {



    return (
        <>
            {/* <Image fluid src={Picture1} style={{ maxHeight: '50em', borderRadius: '15px' }} alt="bedrijfs Logo" /> */}
            <Container fluid className="mt-header-menu">
                <Row>
                    <Col xs={12} s={12} md={12} lg={6} className='picture_background' style={{ backgroundImage: 'linear-gradient(to bottom, transparent 0%, transparent 80%, #46544c 99%), url(' + Picture10 + ')' }}> </Col>
                    <Col xs={12} s={12} md={12} lg={6} className='col_ col_text' style={{ backgroundColor: '#8f9c95', fontSize: "1.5vh", color: '#ffffff' }}><h2>Even voorstellen</h2>
                        <br />
                        Mijn naam is Linda van der Voort, getrouwd en moeder van 2 kids. Jarenlang heb ik met veel plezier gewerkt als IC verpleegkundige. <br />Sporten is al jarenlang een hobby en een heerlijke uitlaadklep als werkende moeder met 2 soms best hectische banen. Ik ben destijds gestart met bootcampen en daarnaast vond ik het heerlijk om hard te lopen.
                        Eigenlijk ben ik altijd wel opzoek naar uitdagingen en zo ben ik bij crossfitten terecht gekomen.<br /><br /> Hier vind je mij wel een aantal uurtjes in de week.
                        Hoe gaaf is het om uiteindelijk van je hobby je werk te maken. <br />Van het geven van bootcamplessen tot het geven van personal trainingen bij de sportschool. <br />Langzaam ging ik steeds meer het roer omgooien totdat ik toch echt een keuze moest gaan maken, en ik ging voor mijn passie!
                        In juli 2022 ben ik mijn eigen sport onderneming begonnen waarin ik mijn enthousiasme, gedrevenheid, kennis en vooral het plezier van sporten wil overbrengen naar de klanten.

                    </Col>
                    <Col xs={12} s={12} md={12} lg={6} style={style.triangle}></Col>
                </Row>
                <Row>

                    <Col xs={{ span: 12, order: 2 }} s={12} lg={7} className='col_ col_text order-xs-2 order-sm-2 order-lg-1' style={{ backgroundColor: '#FFFFFF', fontSize: "1.5vh", color: '#8f9c95' }}><h2>Ik blijf mijzelf graag uitdagen.</h2>
                        <br />
                        Ik vind het gaaf om steeds weer iets nieuws te leren door diverse opleidingen/cursussen te blijven volgen zodat ik elke sporter als individu kan helpen. <br />
                        <br />
                        Van krachttraining met KB’s, barbell tot cardio. <br />
                        Voor de ervaren sporten, de beginner tot aan de pre- en post natale sporter.<br />
                        <br />
                        <a href='/Aanbod' style={{ color: "rgb(143, 156, 149)" }}>Voor meer info kijk onder aanbod.</a><br />
                    </Col>
                    <Col xs={{ span: 12, order: 1 }} s={12} lg={5} className='picture_background order-xs-1 order-sm-1 order-lg-2' style={{ backgroundImage: 'linear-gradient(to bottom, #FFFFFF 0%, transparent 20%, transparent 80%, #FFFFFF 99%),url(' + Picture5 + ')' }}> </Col>
                </Row>
            </Container>
        </>
    )
}

export default Home;